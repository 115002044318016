import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';


import {
    AiOutlineHome,
    AiOutlineRobot
} from 'react-icons/ai'

import {IconContext} from "react-icons";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: 'GetDashboard',
        }, {
            id: uuidv4(),
            link: '/',
            label: 'Bots',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineRobot/></IconContext.Provider>,
            rbac: 'ListBots',
            children: [],
        }] as PlatformNavbarMenuItem[];

};

